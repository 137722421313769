import * as types from './mutation-types'
let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_PUBLICASKING] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.publicAsking = payload
  },
  [types.SET_PUBLICASKINGS] (state, payload) {
    state.publicAskings = payload.data
    state.pagination = payload.meta.pagination
  }
}
