export default [
  {
    name: 'menu',
    path: '/event/menu',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Menu',
      icon: 'dvr',
      label: 'Menu'
    }
  }
]
