import { convertIntToBoolean } from '@/helpers'

export default {
  'WORD_CLOUD' (state) {
    return state.word_cloud
  },
  'WORDS_CLOUD' (state) {
    if (state.words_cloud.length > 0) {
      let wordsCloud = convertIntToBoolean(state.words_cloud, ['status'])
      return wordsCloud
    }
    return []
  }
}
