import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_POLL] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.poll = payload
    if (payload.options) {
      state.poll.options = payload.options.data
    }
  },
  [types.SET_POLLS] (state, payload) {
    state.polls = []
    if (payload.data.length > 0) {
      for (let poll of payload.data) {
        convertIntToBoolean(poll, ['status'])
      }
      state.polls = payload.data
    }
    state.pagination = payload.meta.pagination
  }
}
