import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
  }
})

export default {
  [types.SET_FORM] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.form = payload
  },
  [types.SET_FORMS] (state, payload) {
    state.forms = payload.data
    // state.pagination = payload.meta.pagination
  }
}
