import http from '@/http'
const URL = '/admin/rooms'

export const list = async (params) => {
  const list = (await http.get(URL + `/${params.room_id}/polls?include=options`, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.room_id}/polls/${params.id}?include=options`))
  }
  return one
}

export const save = (payload) => {
  let id = payload.get('id') || ''
  return http.post(URL + `/${payload.get('room_id')}/polls/${id}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const deletePoll = async (payload) => http.delete(URL + `/${payload.room_id}/polls/${payload.id}`)
export const deleteOption = async (payload) => http.delete(URL + `/${payload.room_id}/polls/${payload.id}/option`)
export const changeStatus = (payload) => http.put(URL + `/${payload.room_id}/polls/${payload.id}/status`, payload)
export const orderList = async (payload) => {
  if (payload.room_id) {
    return http.put(URL + `/${payload.room_id}/polls/sequence`, payload)
  }
}
export default {
  list,
  find,
  save,
  changeStatus,
  deletePoll,
  deleteOption,
  orderList
}
