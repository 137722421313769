export default [
  {
    name: 'guide',
    path: '/guide',
    component: () => import(/* webpackChunkName: "event" */ './Show'),
    meta: {
      showNavbar: false,
      title: 'Parceiro',
      icon: 'dvr',
      label: 'Parceiro'
    },
    children: [
      { path: '/guide/hotel', component: () => import(/* webpackChunkName: "event" */ './hotel/Index'), name: 'guide-hotel' },
      { path: '/guide/transfers', component: () => import(/* webpackChunkName: "event" */ './transfers/Index'), name: 'guide-transfers' },
      { path: '/guide/wifi', component: () => import(/* webpackChunkName: "event" */ './wifi/Index'), name: 'guide-wifi' },
      { path: '/guide/activities', component: () => import(/* webpackChunkName: "event" */ './activities/Index'), name: 'guide-activities' }
    ]
  }
]
