import { convertIntToBoolean } from '@/helpers'

export default {
  'QUIZ' (state) {
    return state.quiz
  },
  'QUIZZES' (state) {
    if (state.quizzes.length > 0) {
      let quizzes = convertIntToBoolean(state.quizzes, ['status'])
      return quizzes
    }
    return []
  }
}
