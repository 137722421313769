export default [
  {
    name: 'store-orders',
    path: '/store/orders',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Pedidos',
      icon: 'dvr',
      label: 'Pedidos'
    }
  }
]
