import { convertIntToBoolean } from '@/helpers'

export default {
  'ARCHIVE' (state) {
    return state.archive
  },
  'ARCHIVES' (state) {
    if (state.archives.length > 0) {
      let archives = convertIntToBoolean(state.archives, ['status'])
      return archives
    }
    return []
  }
}
