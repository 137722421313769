import http from '@/http'
const URL = '/admin/gamification'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const store = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}
export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const changeStatusGamification = () => http.put(URL + `/statusGamification`)

export const changeRankingGamification = () => http.put(URL + `/statusRankingGamification`)

export const remove = async (id) => http.delete(URL + `/${id}`)

export const saveGamificationRule = (payload) => http.post(URL + `/rules`, payload)

export const getGamificationRules = () => http.get(URL + `/rules`)

export const getActivities = () => http.get(URL + `/activities`)

export const saveActivity = (payload) => http.post(URL + `/activities`, payload)

export const findActivity = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/activities/${params.id}`))
  }
  return one
}
export const changeStatusActivity = (payload) => http.patch(URL + `/activities/${payload}/status`)

export const resetRanking = () => http.delete(URL + '/reset')

export default {
  list,
  show,
  store,
  changeStatus,
  changeStatusGamification,
  changeRankingGamification,
  remove,
  saveGamificationRule,
  getGamificationRules,
  getActivities,
  saveActivity,
  findActivity,
  changeStatusActivity,
  resetRanking
}
