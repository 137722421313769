import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_PARTICIPANTS, res.data)
  })
)

export const getParticipant = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_PARTICIPANT, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveUser(obj).then(res => {
    commit(types.SET_PARTICIPANT, res.data.data)
    return res
  })
}

export const importUsers = async ({ commit }, obj) => {
  await services.importUsers(obj).then(res => {
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const changeModerator = async ({ commit }, obj) => {
  await services.changeModerator(obj).then(res => {
    return res
  })
}

export const deleteUser = async ({ commit }, obj) => {
  await services.deleteUser(obj).then(res => {
    return res
  })
}

export const downloadData = async ({ commit }, params) => {
  return services.exportData(params).then(res => {
    return res.data
  })
}
