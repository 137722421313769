import { convertIntToBoolean } from '@/helpers'

export default {
  'TRANSFER' (state) {
    return state.transfer
  },
  'TRANSFERS' (state) {
    if (state.transfers.length > 0) {
      let transfers = convertIntToBoolean(state.transfers, ['status'])
      return transfers
    }
    return []
  }
}
