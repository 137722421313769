import http from '@/http'
const URL = '/admin/participants'

export const listSpeakers = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const getSpeaker = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const saveSpeaker = (payload) => http.post(URL + `/${payload.get('id')}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })

export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const deleteSpeaker = async (id) => http.delete(URL + `/${id}`)

export const orderList = async (payload) => http.put(URL + `/sequence`, payload)

export default {
  listSpeakers,
  getSpeaker,
  saveSpeaker,
  changeStatus,
  deleteSpeaker,
  orderList
}
