import * as types from './mutation-types'

let defaultObject = () => ({
  data: {
    description: ''
  }
})

export default {
  [types.SET_RAFFLE] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.raffle = payload
  },
  [types.SET_RAFFLES] (state, payload) {
    console.log(payload)
    state.raffles = payload.data
    state.pagination = payload.meta.pagination
  }
}
