import { convertIntToBoolean } from '@/helpers'

export default {
  'GAMIFICATION' (state) {
    return state.gamification
  },
  'GAMIFICATIONS' (state) {
    const list = convertIntToBoolean(state.gamifications, ['status'])
    return list
  }
}
