import http from '@/http'
const URL = '/admin/partners'

export const list = async (params) => {
  const list = (await http.get(URL + `/${params.partner_id}/archives`, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.partner_id}/archives/${params.id}`))
  }
  return one
}

export const save = (payload) => {
  let id = payload.id || ''
  if (id) {
    return http.put(URL + `/${payload.partner_id}/archives/${id}`, payload)
  }
  return http.post(URL + `/${payload.partner_id}/archives/${id}`, payload)
}

export const deleteArchive = async (payload) => http.delete(URL + `/${payload.partner_id}/archives/${payload.id}`)
export const changeStatus = (payload) => http.put(URL + `/${payload.partner_id}/archives/${payload.id}/status`, payload)

export default {
  list,
  find,
  save,
  changeStatus,
  deleteArchive
}
