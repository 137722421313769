import * as types from './mutation-types'
let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_ASKING] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.asking = payload
  },
  [types.SET_ASKINGS] (state, payload) {
    state.askings = payload.data
    state.pagination = payload.meta.pagination
  }
}
