import * as dashboardSymbols from '@/modules/dashboards/pages/dashboardsSymbols'

function prefixZero (num) {
  return (num + '').padStart(2, '0')// num < 10 ? '0' + num : num
}

export default [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('./pages'),
    children: [
      {
        path: '',
        component: () => import('./pages/RealTime'),
        alias: 'realtime',
        name: 'DashboardRealTime',
        meta: {
          dashboard: {
            title: 'Dashboard',
            pathDat: () => '',
            info: 'Real-time',
            symbol: dashboardSymbols.DADOS_GERAIS_REAL_TIME,
            backRoute: false
          }
        }
      },
      {
        path: 'analytics',
        component: () => import('./pages/Analytics'),
        name: 'DashboardAnalytics',
        meta: {
          dashboard: {
            title: 'Dashboard',
            pathDat: () => '',
            info: 'Analytics',
            symbol: dashboardSymbols.DADOS_GERAIS_ANALYTICS,
            backRoute: false
          }
        }
      },
      {
        path: 'dash-rooms',
        component: () => import('./pages/TodasSalas'),
        name: 'DashboardTodasSalas',
        meta: {
          dashboard: {
            title: 'Dashboard',
            pathDat: () => '/Salas',
            info: 'Analytics',
            symbol: dashboardSymbols.SALAS,
            backRoute: false
          }
        }
      },
      {
        path: 'dash-rooms/:id',
        component: () => import('./pages/RoomDetail'),
        name: 'DashboardRoomDetail',
        meta: {
          dashboard: {
            title: 'Dashboard',
            pathDat: ({ id }) => `/Salas/${prefixZero(id)}`,
            info: 'Analytics',
            symbol: dashboardSymbols.INFO,
            backRoute: ({ id }) => ({ name: 'DashboardTodasSalas', params: { id } })
          }
        }
      },
      {
        path: 'dash-rooms/:id/survey',
        component: () => import('./pages/SurveyDetail'),
        name: 'DashboardSurveyDetail',
        meta: {
          dashboard: {
            title: 'Dashboard',
            pathDat: ({ id }) => `/Salas/${prefixZero(id)}/Enquete`,
            info: 'Analytics',
            symbol: dashboardSymbols.ENQUETE,
            backRoute: ({ id }) => ({ name: 'DashboardTodasSalas', params: { id } })
          }
        }
      },
      {
        path: 'dash-rooms/:id/quiz',
        component: () => import('./pages/QuizDetail'),
        name: 'DashboardQuizDetail',
        meta: {
          dashboard: {
            title: 'Dashboard',
            pathDat: ({ id }) => `/Salas/${prefixZero(id)}/Quiz`,
            info: 'Analytics',
            symbol: dashboardSymbols.QUIZ,
            backRoute: ({ id }) => ({ name: 'DashboardTodasSalas', params: { id } })
          }
        }
      },
      {
        path: 'dash-rooms/:id/word-cloud',
        component: () => import('./pages/WordCloudDetail'),
        name: 'DashboardWordCloudDetail',
        meta: {
          dashboard: {
            title: 'Dashboard',
            pathDat: ({ id }) => `/Salas/${prefixZero(id)}/Quiz`,
            info: 'Analytics',
            symbol: dashboardSymbols.WORD_CLOUD,
            backRoute: ({ id }) => ({ name: 'DashboardTodasSalas', params: { id } })
          }
        }
      }
    ]
  }
]
