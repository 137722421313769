import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_WIFI] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    convertIntToBoolean(payload, ['status'])
    state.wifi = payload
  },
  [types.SET_WIFIS] (state, payload) {
    state.wifis = []
    if (payload.data.length > 0) {
      for (let archive of payload.data) {
        convertIntToBoolean(archive, ['status'])
      }
      state.wifis = payload.data
    }
    state.pagination = payload.meta.pagination
  }
}
