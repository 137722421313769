export default [
  {
    name: 'form',
    path: '/event/form',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Formulário de cadastro',
      icon: 'dvr',
      label: 'Formulário de cadastro'
    }
  }
]
