import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
  }
})

export default {
  [types.SET_SYMPLAEVENT] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.symplaevent = payload
  },
  [types.SET_SYMPLAEVENTS] (state, payload) {
    state.symplaevents = payload.data
    state.symplaeventpagination = { total: payload.total, current_page: payload.current_page }
  },
  [types.SET_SYMPLATICKET] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.symplaticket = payload
  },
  [types.SET_SYMPLATICKETS] (state, payload) {
    state.symplatickets = payload.data
    state.ticketpagination = { total: payload.total, current_page: payload.current_page }
  }
}
