import http from '@/http'
const URL = '/admin/survey'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const find = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=answers`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}

export const deleteAsking = async (id) => http.delete(URL + `/${id}`)
export const deleteAnswer = async (id) => http.delete(URL + `/${id}/answer`)
export const changeStatus = (id) => http.put(URL + `/${id}/status`)
export const changeSurvey = () => http.put(URL + `/status`)
export const orderList = async (payload) => http.put(URL + `/sequence?include=answers`, payload)

export default {
  list,
  find,
  save,
  changeStatus,
  changeSurvey,
  deleteAsking,
  deleteAnswer,
  orderList
}
