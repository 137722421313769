import services from '../services'
import * as types from './mutation-types'

export const show = async ({ commit }, params) => {
  await services.find(params).then(res => {
    commit(types.SET_HOTEL, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_HOTEL, res.data.data)
    return res
  })
}

export const deletePhoto = async ({ commit }, obj) => {
  await services.deletePhoto(obj).then(res => {
    return res
  })
}
