import services from '../services'
import * as types from './mutation-types'

export const list = ({ commit }, params) => (
  services.hotel(params).then(res => {
    commit(types.SET_HOTEL, res.data)
  })
)

export const show = async ({ commit, dispatch }, id) => {
  await services.find(id).then(res => {
    commit(types.SET_HOTEL, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.hotelSave(obj).then(res => {
    commit(types.SET_HOTEL, res.data)
    return res
  })
}
