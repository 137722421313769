import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'
let defaultObject = () => ({
  token: ''
})

export default {
  [types.SET_SETTINGINTERACTION] (state, payload) {
    if (payload) {
      convertIntToBoolean(payload, ['satisfaction_survey', 'gamification', 'gamification_ranking', 'timeline', 'indication', 'certificate'])
    }
    state.settinginteraction = payload
  },
  [types.SET_SETTING] (state, payload) {
    // if (payload) {
    //   convertIntToBoolean(payload, ['satisfaction_survey', 'gamification', 'gamification_ranking', 'timeline', 'indication'])
    // }
    state.setting = payload
  },
  [types.SET_SYMPLA] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.sympla = payload
  },
  [types.SET_ZOOM] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.zoom = payload
  },
  [types.SET_NOTIFICATION] (state, payload) {
    state.notificationList = payload.data
    let pagination = {
      total: payload.total,
      current_page: payload.current_page
    }
    state.paginationnl = pagination
  }
}
