import { convertIntToBoolean } from '@/helpers'

export default {
  'WIFI' (state) {
    return state.wifi
  },
  'WIFIS' (state) {
    if (state.wifis.length > 0) {
      let wifis = convertIntToBoolean(state.wifis, ['status'])
      return wifis
    }
    return []
  }
}
