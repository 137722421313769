import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_FORMS, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_FORM, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveMenu(obj).then(res => {
    commit(types.SET_FORM, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, id) => {
  await services.changeStatus(id).then(res => {
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  await services.orderList(obj).then(res => {
    commit(types.SET_FORMS, res.data)
    return res.data
  })
}

export const deleteInput = async ({ commit }, obj) => {
  const data = await services.deleteInput(obj).then(res => {
    return res
  })
  return data
}
