import http from '@/http'
const URL = '/admin/guide/transfers'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.id}`))
  }
  return one
}

export const save = (payload) => {
  let id = payload.id || ''
  return http.post(URL + `/${id}`, payload)
}

export const deleteTransfer = async (payload) => http.delete(URL + `/${payload.id}`)

export default {
  list,
  find,
  save,
  deleteTransfer
}
