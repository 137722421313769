import services from '../services'
import * as types from './mutation-types'

export const getList = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_PRODUCTS, res.data)
  })
)

export const show = async ({ commit }, params) => {
  await services.find(params).then(res => {
    commit(types.SET_PRODUCT, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_PRODUCT, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const deleteProduct = async ({ commit }, obj) => {
  await services.deleteProduct(obj).then(res => {
    return res
  })
}
