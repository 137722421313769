export default [
  {
    name: 'raffle',
    path: '/raffle',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Sorteio',
      icon: 'dvr',
      label: 'Sorteio'
    }
  }
]
