export default [
  {
    name: 'template-email',
    path: '/event/template-email',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'E-mail',
      icon: 'dvr',
      label: 'E-mail'
    }
  },
  {
    name: 'template-email-edit',
    path: '/event/template-email/:id/show',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'E-mail - Edição',
      icon: 'dvr',
      label: 'E-mail - Edição'
    }
  }
]
