import services from '../services'
import * as types from './mutation-types'
import * as storage from '@/modules/auth/storage'

export const GetRooms = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_ROOMS, res.data)
  })
)

export const GetRoom = async ({ commit, dispatch }, id) => {
  await services.find(id).then(res => {
    commit(types.SET_ROOM, res.data.data)
  })

  let token = storage.getLocalTokenChat()

  if (!token) {
    dispatch('loginChatApi')
  }
}

export const loginChatApi = async ({ commit }) => {
  await services.connectChat({ client_id: 2 }).then(res => {
    commit(types.SET_CHAT, res.data)
    storage.setLocalTokenChat(res.data.token)
  })
}

export const SaveRoom = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_ROOM, res.data.data)
    return res
  })
}

export const saveGroupId = async ({ commit }, obj) => {
  await services.saveGroup(obj).then(res => {
    commit(types.SET_ROOM, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const changeStatusAskings = async ({ commit }, obj) => {
  await services.changeStatusAsking(obj).then(res => {
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  await services.orderList(obj).then(res => {
    commit(types.SET_ROOMS, res.data)
    return res.data
  })
}

export const addSpeakers = async ({ commit }, obj) => {
  await services.addSpeakers(obj).then(res => {
    return res
  })
}

export const deleteRoom = async ({ commit }, obj) => {
  await services.deleteRoom(obj).then(res => {
    return res
  })
}
