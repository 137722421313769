export default [
  {
    name: 'feed',
    path: '/feed',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Feed',
      icon: 'dvr',
      label: 'Feed'
    }
  }
]
