import * as types from './mutation-types'

let defaultObject = () => ({
  data: {
    message: '',
    url_action: '',
    cta: ''
  }
})

export default {
  [types.SET_CALL_ACTION] (state, payload) {
    if (payload.data === null) {
      payload = defaultObject()
    }
    state.call_action = payload.data
  }
}
