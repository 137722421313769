import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    name: '',
    about: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    youtube: '',
    website: ''
  }
})

export default {
  [types.SET_PARTNER] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    if (payload.facebook === null) {
      payload.facebook = ''
    }
    if (payload.instagram === null) {
      payload.instagram = ''
    }
    if (payload.linkedin === null) {
      payload.linkedin = ''
    }
    if (payload.twitter === null) {
      payload.twitter = ''
    }
    if (payload.youtube === null) {
      payload.youtube = ''
    }
    if (payload.website === null) {
      payload.website = ''
    }
    state.partner = payload
  },
  [types.SET_PARTNERS] (state, payload) {
    state.partners = payload.data
    state.pagination = payload.meta.pagination
  }
}
