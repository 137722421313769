import http from '@/http'
const URL = '/admin/rooms'
const URL_CHAT = 'https://chat.eventfy.live:2617'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const find = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=settings,streamings,askings.user`))
  }
  return one
}

export const save = (payload) => http.post(URL + `/${payload.get('id')}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })

export const saveGroup = (payload) => http.post(URL + `/${payload.room_id}/group`, payload)

export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const deleteRoom = async (id) => http.delete(URL + `/${id}`)

export const roomSettings = async (id) => http.get(URL + `/${id}/settings`)

export const toogleSetting = async (payload) => {
  return http.put(URL + `/${payload.id}/settings`, payload)
}

export const saveStreaming = async (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.room_id}/streamings/${payload.id}`, payload)
  }
  return http.post(URL + `/${payload.room_id}/streamings`, payload)
}

export const changeStatusAsking = (payload) => http.put(URL + `/${payload.room_id}/${payload.id}/status`, payload)

export const orderList = async (payload) => http.put(URL + `/sequence`, payload)

export const addSpeakers = (payload) => http.put(URL + `/${payload.room_id}/speakers`, payload)

export const connectChat = (payload) => {
  return http.post(URL_CHAT + '/sessions/client', payload)
}

export default {
  list,
  find,
  save,
  saveGroup,
  changeStatus,
  deleteRoom,
  roomSettings,
  toogleSetting,
  saveStreaming,
  changeStatusAsking,
  orderList,
  addSpeakers,
  connectChat
}
