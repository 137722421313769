import http from '@/http'
const URL = '/admin/rooms'

export const list = async (params) => {
  const list = (await http.get(URL + `/${params.room_id}/word_cloud?include=words`, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.room_id}/word_cloud/${params.id}?include=words,badWords`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.room_id}/word_cloud/${payload.id}`, payload)
  }
  return http.post(URL + `/${payload.room_id}/word_cloud`, payload)
}

export const deleteWordCloud = async (payload) => http.delete(URL + `/${payload.room_id}/word_cloud/${payload.id}`)
export const deleteOption = async (payload) => http.delete(URL + `/${payload.room_id}/word_cloud/${payload.id}/word/${payload.word}`)
export const changeStatus = (payload) => http.put(URL + `/${payload.room_id}/word_cloud/${payload.id}/status`, payload)

export default {
  list,
  find,
  save,
  changeStatus,
  deleteWordCloud,
  deleteOption
}
