import http from '@/http'
const URL = '/admin/partners'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const find = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=settings,streamings,askings.user,category`))
  }
  return one
}
export const findCategory = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/categories/${params.id}`))
  }
  return one
}
export const getCategories = async (params) => {
  const list = (await http.get(URL + `/categories`, { params: params }))
  return list
}

export const save = (payload) => http.post(URL + `/${payload.get('id')}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
export const saveCategory = (payload) => {
  let id = payload.id || ''
  return http.post(URL + `/categories/${id}`, payload)
}
export const changeStatus = (payload) => http.put(URL + `/${payload}/status`)

export const deletePartner = async (id) => http.delete(URL + `/${id}`)
export const deleteCategory = async (payload) => http.delete(URL + `/categories/${payload.id}`)

export const saveStreaming = async (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.room_id}/streamings/${payload.id}`, payload)
  }
  return http.post(URL + `/${payload.room_id}/streamings`, payload)
}

export const orderList = async (payload) => http.put(URL + `/sequence`, payload)

export default {
  list,
  getCategories,
  find,
  findCategory,
  save,
  saveCategory,
  changeStatus,
  deletePartner,
  deleteCategory,
  saveStreaming,
  orderList
}
