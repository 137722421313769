export default [
  {
    name: 'faq',
    path: '/faq',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'FAQ',
      icon: 'dvr',
      label: 'FAQ'
    }
  }
]
