import axios from 'axios'
// import services from './services'
// import interceptors from './interceptors'

const http = axios.create({
  baseURL: process.env.VUE_APP_DEFAULT_AUTH
})

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.common['Authorization'] = `Bearer ${token}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

http.interceptors.response.use(
  response => {
    // if (response.config.method.toUpperCase() === 'POST') {
    //   this.events.$emit('enable-cancel-salve-buttons')
    // }
    // if (response.data.restore) {
    //   deleteSuccessMessage(response.data.message, response.data.restore, response.data.ids)
    // } else if (response.data.message) {
    //   successMessage(response.data.message)
    // }
    return response
  },
  error => {
    // if (error.config.method.toUpperCase() === 'POST') {
    //   this.events.$emit('enable-cancel-salve-buttons')
    // }
    if (error.response.status === 500 && !error.response.data.message) {
      // serverErrorMessage('Erro: Oops! Isto precisa da sua atenção: ocorreu um falha inesperada. Por favor contacte nossa equipe de suporte a clientes.')
      return Promise.reject(error)
    }

    if (error.response.status === 400 && !error.response.data.message) {
      // serverErrorMessage('Erro: Oops! Isto precisa da sua atenção: ocorreu um falha inesperada. Por favor contacte nossa equipe de suporte a clientes.')
      return Promise.reject(error)
    }

    if (error.response.data.errors) {
      let messages = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
      console.log(messages)
    } else if (error.response.status === 422) {
      if (error.response.data.message) console.log(error.response.data.message)
    } else if (error.response.status === 401) {
      if (error.response.config.url.includes('https://chat.eventfy.live')) {
        localStorage.removeItem('token-chat')
        return Promise.reject(error)
      }
      localStorage.removeItem('token')
      // window.location.href = `http://${process.env.HOST || 'localhost'}:${process.env.PORT || 8090}`
      if (!window.location.pathname.includes('login')) {
        window.location.href = `${window.location.protocol}//${window.location.host}/login?redirect=${window.location.pathname}`
      }
    } else {
      console.log(error.response.data.message)
    }
    return Promise.reject(error.response.data)
  }
)

const setBearerToken = token => {
  http.interceptors.request.use(function (config) {
    config.headers.common['Authorization'] = `Bearer ${token}`
    return config
  }, function (error) {
    return Promise.reject(error)
  })
}

// export default services
export default http
export { setBearerToken }
