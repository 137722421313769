export default [
  {
    name: 'roles',
    path: '/roles',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Função',
      icon: 'dvr',
      label: 'Função'
    }
  }
]
