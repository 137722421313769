import services from '../services'
import * as types from './mutation-types'

export const getList = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_WIFIS, res.data)
  })
)

export const show = async ({ commit }, params) => {
  await services.find(params).then(res => {
    commit(types.SET_WIFI, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_WIFI, res.data.data)
    return res
  })
}

export const deleteWifi = async ({ commit }, obj) => {
  await services.deleteWifi(obj).then(res => {
    return res
  })
}
