import http from '@/http'
const URL = '/admin/rooms'

export const list = async (params) => {
  const list = (await http.get(URL + `/${params.room_id}/publicAskings?include=user`, { params: params }))
  return list
}

export const find = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=user`))
  }
  return one
}

export const save = (payload) => http.put(URL + `/${payload.room_id}/publicAskings/${payload.id}/reply`, payload)

export const deleteAsking = async (payload) => http.delete(URL + `/${payload.room_id}/publicAskings/${payload.id}`)

export const changeStatus = (payload) => http.put(URL + `/${payload.room_id}/publicAskings/${payload.id}/status`, payload)

export default {
  list,
  find,
  save,
  changeStatus,
  deleteAsking
}
