import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_WORD_CLOUD] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.word_cloud = payload
    if (payload.words) {
      state.word_cloud.words = payload.words.data
    }
  },
  [types.SET_WORDS_CLOUD] (state, payload) {
    state.word_clouds = []
    if (payload.data.length > 0) {
      for (let word of payload.data) {
        convertIntToBoolean(word, ['status'])
      }
      state.words_cloud = payload.data
    }
    state.pagination = payload.meta.pagination
  }
}
