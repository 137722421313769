export default [
  {
    name: 'reports',
    path: '/reports',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Relatórios',
      icon: 'dvr',
      label: 'Relatórios'
    },
    children: [
      { path: '/reports/participants', component: () => import(/* webpackChunkName: "event" */ './participants/Index'), name: 'report-participants' },
      { path: '/reports/indication', component: () => import(/* webpackChunkName: "event" */ './indication/Index'), name: 'report-indication' },
      { path: '/reports/feed-timeline', component: () => import(/* webpackChunkName: "event" */ './timeline/Index'), name: 'report-feed-timeline' },
      { path: '/reports/askings', component: () => import(/* webpackChunkName: "event" */ './askings/Index'), name: 'report-askings' },
      { path: '/reports/emojis', component: () => import(/* webpackChunkName: "event" */ './emojis/Index'), name: 'report-emojis' },
      { path: '/reports/quiz', component: () => import(/* webpackChunkName: "event" */ './quiz/Index'), name: 'report-quiz' },
      { path: '/reports/polls', component: () => import(/* webpackChunkName: "event" */ './poll/Index'), name: 'report-polls' },
      { path: '/reports/satisfaction-survey', component: () => import(/* webpackChunkName: "event" */ './survey/Index'), name: 'report-satisfaction-survey' },
      { path: '/reports/generated-certificates', component: () => import(/* webpackChunkName: "event" */ './certificates/Index'), name: 'report-generated-certificates' },
      { path: '/reports/word-cloud', component: () => import(/* webpackChunkName: "event" */ './word-cloud/Index'), name: 'report-word-cloud' },
      { path: '/reports/gamification', component: () => import(/* webpackChunkName: "event" */ './gamification/Index'), name: 'report-gamification' },
      { path: '/reports/logs-auth', component: () => import(/* webpackChunkName: "event" */ './logs-auth/Index'), name: 'report-logs-auth' }
    ]
  }
]
