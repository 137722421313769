import http from '@/http'
const URL = '/admin/store'

export const list = async (params) => {
  const list = (await http.get(URL + `/orders`, { params: params }))
  return list
}
export const find = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/orders/${id}?include=user,items.product.category`))
  }
  return one
}

export const save = (payload) => {
  let id = payload.id || ''
  return http.post(URL + `/orders/${id}`, payload)
}

export const changeStatus = (payload) => http.put(URL + `/orders/${payload.id}/status`, payload)

export default {
  list,
  save,
  find,
  changeStatus
}
