import services from '../services'
import * as types from './mutation-types'

export const getList = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_ARCHIVES, res.data)
  })
)

export const show = async ({ commit }, params) => {
  await services.find(params).then(res => {
    commit(types.SET_ARCHIVE, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_ARCHIVE, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const deleteArchive = async ({ commit }, obj) => {
  await services.deleteArchive(obj).then(res => {
    return res
  })
}
