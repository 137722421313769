import http from '@/http'
const URL = '/admin/partners'

export const list = async (params) => {
  const list = (await http.get(URL + `/${params.partner_id}/contacts`, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.partner_id}/contacts/${params.id}?include=user`))
  }
  return one
}

export const exportContacts = async (params) => {
  const data = (await http.get(URL + `/${params.partner_id}/contacts/export`, { params: params, responseType: 'blob' })).data
  return data
}

export default {
  list,
  find,
  exportContacts
}
