import http from '@/http'
const URL = '/admin/guide/hotel'

export const find = async (params) => {
  let one = null
  one = (await http.get(URL + '?include=photos'))
  return one
}

export const save = (payload) => {
  return http.post(URL, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const deletePhoto = async (payload) => http.delete(URL + `/photo/${payload.id}`)

export default {
  find,
  save,
  deletePhoto
}
