import { convertIntToBoolean } from '@/helpers'

export default {
  'PRODUCT' (state) {
    return state.product
  },
  'PRODUCTS' (state) {
    if (state.products.length > 0) {
      let products = convertIntToBoolean(state.products, ['status'])
      return products
    }
    return []
  }
}
