import http from '@/http'
const URL = '/admin/raffles'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=winner.user`))
  }
  return one
}

export const store = (payload) => {
  if (payload.get('id')) {
    return http.post(URL + `/${payload.get('id')}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
  }
  return http.post(URL, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}
export const deleteRaffle = async (id) => http.delete(URL + `/${id}`)

export default {
  list,
  show,
  store,
  deleteRaffle
}
