export default {
  'SYMPLAEVENT' (state) {
    return state.symplaevent
  },
  'SYMPLAEVENTS' (state) {
    return state.symplaevents
  },
  'SYMPLATICKETS' (state) {
    return state.symplatickets
  },
  'SYMPLATICKET' (state) {
    return state.symplaticket
  }
}
