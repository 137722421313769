import * as types from './mutation-types'
let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_ORDER] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.order = payload
  },
  [types.SET_ORDERS] (state, payload) {
    state.orders = payload.data
    state.pagination = payload.meta.pagination
  }
}
