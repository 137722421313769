import { store as auth } from '@/modules/auth'
import { store as event } from '@/pages/event'
import { store as settingFeed } from '@/pages/event/feed'
import { store as users } from '@/pages/users'
import { store as roles } from '@/pages/roles'
import { store as sympla } from '@/pages/event/sympla'
import { store as emailTemplate } from '@/pages/event/emailTemplate'
import { store as menu } from '@/pages/event/menu'
import { store as form } from '@/pages/event/form'
import { store as speaker } from '@/pages/speakers'
import { store as schedule } from '@/pages/schedules'
import { store as room } from '@/pages/rooms'
import { store as partner } from '@/pages/partners'
import { store as product } from '@/pages/store/products'
import { store as order } from '@/pages/store/orders'
import { store as faq } from '@/pages/faq'
import { store as guide } from '@/pages/guide'
import { store as hotel } from '@/pages/guide/hotel'
import { store as transfer } from '@/pages/guide/transfers'
import { store as wifi } from '@/pages/guide/wifi'
import { store as activities } from '@/pages/guide/activities'
import { store as partnerVideos } from '@/pages/partners/videos'
import { store as partnerArchives } from '@/pages/partners/archives'
import { store as partnerProducts } from '@/pages/partners/products'
import { store as partnerContacts } from '@/pages/partners/contacts'
import { store as asking } from '@/pages/rooms/askings'
import { store as publicAsking } from '@/pages/rooms/publicAskings'
import { store as quiz } from '@/pages/rooms/quiz'
import { store as poll } from '@/pages/rooms/poll'
import { store as streaming } from '@/pages/rooms/transmission'
import { store as callAction } from '@/pages/rooms/call-action'
import { store as wordCloud } from '@/pages/rooms/word-cloud'
import { store as participant } from '@/pages/participants'
import { store as feed } from '@/pages/feed'
import { store as survey } from '@/pages/survey'
import { store as gamification } from '@/pages/gamification'
import { store as archive } from '@/pages/archives'
import { store as video } from '@/pages/videos'
import { store as raffle } from '@/pages/raffle'
import dashboards from '@/modules/dashboards/store'

export default {
  auth,
  event,
  settingFeed,
  users,
  roles,
  sympla,
  emailTemplate,
  menu,
  form,
  speaker,
  schedule,
  room,
  asking,
  faq,
  guide,
  hotel,
  wifi,
  transfer,
  activities,
  publicAsking,
  quiz,
  poll,
  streaming,
  callAction,
  wordCloud,
  participant,
  feed,
  survey,
  gamification,
  archive,
  video,
  raffle,
  partner,
  product,
  order,
  partnerVideos,
  partnerArchives,
  partnerProducts,
  partnerContacts,
  dashboards
}
