import { convertIntToBoolean } from '@/helpers'

export default {
  'VIDEO' (state) {
    return state.video
  },
  'VIDEOS' (state) {
    if (state.videos.length > 0) {
      let videos = convertIntToBoolean(state.videos, ['status'])
      return videos
    }
    return []
  }
}
