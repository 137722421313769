import services from '../services'
import * as types from './mutation-types'

export const GetSetting = ({ commit }) => (
  services.setting().then(res => {
    commit(types.SET_SETTING, res.data.data)
  })
)

export const GetSettingInteraction = ({ commit }) => (
  services.settingInteraction().then(res => {
    commit(types.SET_SETTINGINTERACTION, res.data.data)
  })
)

export const GetSympla = ({ commit }) => (
  services.symplaToken().then(res => {
    commit(types.SET_SYMPLA, res.data.data)
  })
)

export const GetZoom = ({ commit }) => (
  services.zoomKeys().then(res => {
    commit(types.SET_ZOOM, res.data.data)
  })
)

export const SaveSettingIntegration = async ({ commit }, obj) => {
  await services.saveIntegration(obj).then(res => {
    commit(types.SET_SETTING, res.data.data)
    return res
  })
}

export const SaveSympla = async ({ commit }, obj) => {
  await services.saveToken(obj).then(res => {
    commit(types.SET_SYMPLA, res.data.data)
  })
}

export const SaveZoomKey = async ({ commit }, obj) => {
  await services.saveKeys(obj).then(res => {
    commit(types.SET_ZOOM, res.data.data)
  })
}

export const getNotificationAll = ({ commit }, params) => (
  services.getNotificationAll(params).then(res => {
    commit(types.SET_NOTIFICATION, res.data)
  })
)

export const createNotification = async ({ commit }, obj) => {
  await services.createNotification(obj).then(res => {
    return res
  })
}

export const sendNotification = async ({ commit }, obj) => {
  await services.sendNotification(obj).then(res => {
    return res
  })
}

export const SaveSetting = async ({ commit }, obj) => {
  await services.saveSetting(obj).then(res => {
    commit(types.SET_SETTING, res.data.data)
    return res
  })
}

export const SaveSettingAccount = async ({ commit }, obj) => {
  await services.saveSettingAccount(obj).then(res => {
    // commit(types.SET_SETTING, res.data.data)
    return res
  })
}

export const changeEmoji = async ({ commit }, obj) => {
  await services.changeEmoji(obj).then(res => {
    return res
  })
}

export const changeColors = async ({ commit }, obj) => {
  await services.changeColors(obj).then(res => {
    commit(types.SET_SETTING, res.data.data)
    return res
  })
}

export const DeletePageCert = async ({ commit }, obj) => {
  await services.deletePage(obj).then(res => {
    return res
  })
}
