import http from '@/http'
const URL = '/admin/guide/activities'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.id}`))
  }
  return one
}

export const save = (payload) => {
  let id = payload.id || ''
  if (id) {
    return http.put(URL + `/${id}`, payload)
  }
  return http.post(URL + `/${id}`, payload)
}

export const deleteActivitie = async (payload) => http.delete(URL + `/${payload.id}`)
export const changeStatus = (payload) => http.put(URL + `/${payload.id}/status`, payload)
export const exportParticipants = async (params) => {
  const data = (await http.get(URL + `/${params.id}/export`, { params: params, responseType: 'blob' })).data
  return data
}
export default {
  list,
  find,
  save,
  changeStatus,
  deleteActivitie,
  exportParticipants
}
