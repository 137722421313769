import http from '@/http'
const URL = '/admin/reports'

export const listGamification = async (params) => {
  const list = (await http.get(URL + '/gamification?include=user', { params: params })).data
  return list
}

export const exportGamification = async (params) => {
  const data = (await http.get(URL + '/gamification/export?include=user', { params: params, responseType: 'blob' })).data
  return data
}

export const getGamificationDetail = async (params) => {
  const list = (await http.get(URL + `/gamification/${params}?include=user,setting.gamification`)).data
  return list
}

export const listIndication = async (params) => {
  const list = (await http.get(URL + '/indication?include=indicator', { params: params })).data
  return list
}

export const exportIndication = async (params) => {
  const data = (await http.get(URL + '/indication/export?include=indicator', { params: params, responseType: 'blob' })).data
  return data
}

export const getIndicationDetail = async (params) => {
  const list = (await http.get(URL + `/indication/${params}?include=indication.indicated`)).data
  return list
}

export const deleteIndication = async (params) => {
  const list = (await http.delete(URL + `/indication/${params}`)).data
  return list
}

export const listPostsTimeline = async (params) => {
  const list = (await http.get(URL + '/posts-timeline', { params: params })).data
  return list
}

export const exportPostsTimeline = async (params) => {
  const data = (await http.get(URL + '/posts-timeline/export', { params: params, responseType: 'blob' })).data
  return data
}

export const listLogsAuth = async (params) => {
  const list = (await http.get(URL + '/logs-auth', { params: params })).data
  return list
}

export const exportLogsAuth = async (params) => {
  const data = (await http.get(URL + '/logs-auth/export', { params: params, responseType: 'blob' })).data
  return data
}

export const listParticipants = async (params) => {
  const list = (await http.get(URL + '/participants?include=indication.indicated', { params: params })).data
  return list
}

export const exportParticipants = async (params) => {
  const data = (await http.get(URL + '/participants/export', { params: params, responseType: 'blob' })).data
  return data
}

export const listCertificates = async (params) => {
  const list = (await http.get(URL + '/certificates', { params: params })).data
  return list
}

export const exportCertificates = async (params) => {
  const data = (await http.get(URL + '/certificates/export', { params: params, responseType: 'blob' })).data
  return data
}

export const listAskings = async (params) => {
  const list = (await http.get(URL + '/askings?include=user,room', { params: params })).data
  return list
}

export const exportAskings = async (params) => {
  const data = (await http.get(URL + '/askings/export?include=user,room', { params: params, responseType: 'blob' })).data
  return data
}

export const listResultQuiz = async (params) => {
  const list = (await http.get(URL + '/quiz', { params: params })).data
  return list
}

export const exportResultQuiz = async (params) => {
  const data = (await http.get(URL + '/quiz/export', { params: params, responseType: 'blob' })).data
  return data
}

export const listResultPoll = async (params) => {
  const list = (await http.get(URL + '/polls', { params: params })).data
  return list
}

export const exportResultPoll = async (params) => {
  const data = (await http.get(URL + '/polls/export', { params: params, responseType: 'blob' })).data
  return data
}

export const listResultSurvey = async (params) => {
  const list = (await http.get(URL + '/survey', { params: params })).data
  return list
}

export const exportResultSurvey = async (params) => {
  const data = (await http.get(URL + '/survey/export', { params: params, responseType: 'blob' })).data
  return data
}

export const listResultWordCloud = async (params) => {
  const list = (await http.get(URL + '/word-cloud?include=words,room', { params: params })).data
  return list
}

export const exportResultWordCloud = async (params) => {
  const data = (await http.get(URL + '/word-cloud/export?include=words,room', { params: params, responseType: 'blob' })).data
  return data
}

export default {
  listGamification,
  exportGamification,
  getGamificationDetail,
  listIndication,
  exportIndication,
  getIndicationDetail,
  deleteIndication,
  listParticipants,
  exportParticipants,
  listCertificates,
  exportCertificates,
  listAskings,
  exportAskings,
  listResultQuiz,
  exportResultQuiz,
  listResultPoll,
  exportResultPoll,
  listResultSurvey,
  exportResultSurvey,
  listResultWordCloud,
  exportResultWordCloud,
  listLogsAuth,
  exportLogsAuth,
  listPostsTimeline,
  exportPostsTimeline
}
