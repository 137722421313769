import http from '@/http'
const URL = '/admin/rooms'

export const list = async (params, roomId) => {
  const list = (await http.get(URL + `/${roomId}/call_action`, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.room_id}/call_action/${params.id}`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.room_id}/call_action/${payload.id}`, payload)
  }
  return http.post(URL + `/${payload.room_id}/call_action`, payload)
}

export default {
  list,
  find,
  save
}
