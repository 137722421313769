export default [
  {
    name: 'participants',
    path: '/participants',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Participantes',
      icon: 'dvr',
      label: 'Participantes'
    }
  },
  {
    name: 'participant-edit',
    path: '/participants/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Participante - Edição',
      icon: 'dvr',
      label: 'Participante - Edição'
    }
  },
  {
    name: 'participant-new',
    path: '/participants/new',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Participante - Adicionar',
      icon: 'dvr',
      label: 'Participante - Adicionar'
    }
  }
]
