import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {
    description: ''
  }
})

export default {
  [types.SET_GAMIFICATION] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.gamification = payload
  },
  [types.SET_GAMIFICATIONS] (state, payload) {
    state.gamifications = []
    if (payload.data.length > 0) {
      for (let gamification of payload.data) {
        convertIntToBoolean(gamification, ['status'])
      }
      state.gamifications = payload.data
    }
    state.pagination = payload.meta.pagination
  }
}
