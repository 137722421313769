import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    name: ''
  }
})

export default {
  [types.SET_HOTEL] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.hotel = payload
  }
}
