import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_FEEDS, res.data)
  })
)

export const getPost = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_FEED, res.data.data)
  })
}

export const remPost = async ({ commit }, obj) => {
  await services.deletePost(obj).then(res => {
    return res
  })
}

export const deleteComment = async ({ commit }, obj) => {
  await services.deleteComment(obj).then(res => {
    return res
  })
}
