export default [
  {
    name: 'videos',
    path: '/videos',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Vídeos',
      icon: 'dvr',
      label: 'Vídeos'
    }
  },
  {
    name: 'video-edit',
    path: '/videos/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Vídeo - Edição',
      icon: 'dvr',
      label: 'Vídeo - Edição'
    }
  },
  {
    name: 'video-new',
    path: '/videos/new',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Vídeo - Adicionar',
      icon: 'dvr',
      label: 'Vídeo - Adicionar'
    }
  }
]
