import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_PRODUCT] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.product = payload
  },
  [types.SET_PRODUCTS] (state, payload) {
    if (payload.data.length > 0) {
      for (let product of payload.data) {
        convertIntToBoolean(product, ['status'])
      }
      state.products = payload.data
    }
    state.products = payload.data
    state.pagination = payload.meta.pagination
  }
}
