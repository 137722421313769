export default [
  {
    name: 'partners',
    path: '/partners',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Parceiros',
      icon: 'dvr',
      label: 'Parceiros'
    }
  },
  {
    name: 'partner-edit',
    path: '/partners/edit/:id',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Parceiro - Edição',
      icon: 'dvr',
      label: 'Parceiro - Edição'
    }
  },
  {
    name: 'partner-show',
    path: '/partners/:id/details',
    component: () => import(/* webpackChunkName: "event" */ './Show'),
    meta: {
      showNavbar: false,
      title: 'Parceiro',
      icon: 'dvr',
      label: 'Parceiro'
    },
    children: [
      { path: '/partners/:id/details/videos', component: () => import(/* webpackChunkName: "event" */ './videos/Index'), name: 'partner-videos' },
      { path: '/partners/:id/details/archives', component: () => import(/* webpackChunkName: "event" */ './archives/Index'), name: 'partner-archives' },
      { path: '/partners/:id/details/products', component: () => import(/* webpackChunkName: "event" */ './products/Index'), name: 'partner-products' },
      { path: '/partners/:id/details/contacts', component: () => import(/* webpackChunkName: "event" */ './contacts/Index'), name: 'partner-contacts' }
    ]
  },
  {
    name: 'partner-new',
    path: '/partners/novo',
    component: () => import(/* webpackChunkName: "event" */ './Form'),
    meta: {
      showNavbar: false,
      title: 'Parceiro - Adicionar',
      icon: 'dvr',
      label: 'Parceiro - Adicionar'
    }
  }
]
