import { convertIntToBoolean } from '@/helpers'

export default {
  'STREAMING' (state) {
    return state.streaming
  },
  'STREAMINGS' (state) {
    if (state.streamings.length > 0) {
      let streamings = convertIntToBoolean(state.streamings, ['status'])
      return streamings
    }
    return []
  }
}
