import { convertIntToBoolean } from '@/helpers'

export default {
  'CONTACT' (state) {
    return state.contact
  },
  'CONTACTS' (state) {
    if (state.contacts.length > 0) {
      let contacts = convertIntToBoolean(state.contacts, ['status'])
      return contacts
    }
    return []
  }
}
