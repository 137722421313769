export default [
  {
    name: 'gamification',
    path: '/gamification',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Gamificação',
      icon: 'dvr',
      label: 'Gamificação'
    }
  }
]
