import http from '@/http'
const URL = '/admin/roles'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const listPermissions = async (params) => {
  const list = (await http.get(URL + '/permissions', { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=permissions`))
  }
  return one
}

export const saveRole = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}
export const deleteRole = async (id) => http.delete(URL + `/${id}`)

export default {
  list,
  listPermissions,
  show,
  saveRole,
  deleteRole
}
