import http from '@/http'
const URL = '/admin/settings'

export const setting = () => http.get(URL + '?include=colors,interactions,integrations,account,images')
export const settingInteraction = () => http.get(URL + '/interactions')
export const symplaToken = () => http.get(URL + '/sympla')
export const zoomKeys = () => http.get(URL + '/zoom')
export const saveSetting = (payload) => http.post(URL + `/${payload.get('id')}`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
export const saveSettingAccount = (payload) => http.post(URL + `/account`, payload)
export const saveIntegration = (payload) => http.post(URL + `/integration/`, payload)
export const getNotificationAll = async (params) => {
  const list = (await http.get(URL + `/notifications`, { params: params }))
  return list
}

export const sendNotification = (id) => http.post(URL + `/pusher/${id}`)
export const createNotification = (payload) => http.post(URL + `/create/pusher`, payload)
export const saveToken = (payload) => http.post(URL + `/sympla`, payload)
export const saveKeys = (payload) => http.post(URL + `/zoom`, payload)
export const changeEmoji = (payload) => http.post(URL + `/changeEmoji`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
export const changeColors = (payload) => http.post(URL + `/colors`, payload)
export const saveTerms = (payload) => http.post(URL + `/terms`, payload)
export const getTerms = () => http.get(URL + `/terms`)
export const deletePage = async (payload) => http.delete(URL + `/cert/${payload.id}/page`)

export default {
  setting,
  settingInteraction,
  symplaToken,
  zoomKeys,
  saveToken,
  saveKeys,
  saveSetting,
  saveSettingAccount,
  saveIntegration,
  getNotificationAll,
  sendNotification,
  createNotification,
  changeEmoji,
  changeColors,
  saveTerms,
  getTerms,
  deletePage
}
