import http from '@/http'
const URL = '/admin/settings/sympla'
export const show = () => http.get(URL + '/event')
export const getAllEvents = () => http.get(URL + '/event')
export const getEvents = () => http.get(URL + '/events')
export const saveSymplaEvent = (payload) => http.post(URL + '/events', payload)
export const deleteEvent = (id) => http.delete(URL + `/event/${id}`)

export const getTickets = async (params) => {
  const list = (await http.get(URL + '/tickets', { params: params }))
  return list
}

export const showTicket = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/tickets/${id}`))
  }
  return one
}

export const saveTicket = (payload) => {
  if (payload.id) {
    return http.put(URL + `/tickets/${payload.id}`, payload)
  }
  return http.post(URL + '/tickets', payload)
}
export const deleteTicket = (id) => http.delete(URL + `/tickets/${id}`)

export default {
  show,
  getEvents,
  getAllEvents,
  saveSymplaEvent,
  deleteEvent,
  getTickets,
  showTicket,
  saveTicket,
  deleteTicket
}
