import http from '@/http'
const URL = '/admin/settings/form'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const saveMenu = (payload) => {
  let id = ''
  if (payload.id) {
    id = payload.id
  }
  return http.post(URL + `/${id}`, payload)
}
export const sendEmail = (payload) => {
  return http.post(URL + '/test', payload)
}
export const changeStatus = (id) => http.put(URL + `/${id}/status`)

export const orderList = async (payload) => http.put(URL + `/sequence`, payload)

export const deleteInput = async (id) => http.delete(URL + `/${id}`)

export default {
  list,
  show,
  orderList,
  saveMenu,
  sendEmail,
  changeStatus,
  deleteInput
}
