import { convertIntToBoolean } from '@/helpers'

export default {
  'ACTIVITIE' (state) {
    return state.activitie
  },
  'ACTIVITIES' (state) {
    if (state.activities.length > 0) {
      let activities = convertIntToBoolean(state.activities, ['status'])
      return activities
    }
    return []
  }
}
