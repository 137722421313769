import { convertIntToBoolean } from '@/helpers'

export default {
  'POLL' (state) {
    return state.poll
  },
  'POLLS' (state) {
    if (state.polls.length > 0) {
      let polls = convertIntToBoolean(state.polls, ['status'])
      return polls
    }
    return []
  }
}
