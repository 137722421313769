import * as types from './mutation-types'
// import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_SURVEY] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.survey = payload
    if (payload.answers) {
      state.survey.answers = payload.answers.data
    }
  },
  [types.SET_SURVEYS] (state, payload) {
    state.surveys = []
    if (payload.data.length > 0) {
      // for (let survey of payload.data) {
      //   convertIntToBoolean(survey, ['status'])
      // }
      state.surveys = payload.data
    }
    state.pagination = payload.meta.pagination
  }
}
