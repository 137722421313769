import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
    name: '',
    title: ''
  }
})

export default {
  [types.SET_ROOM] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.room = payload
  },
  [types.SET_SETTING] (state, payload) {
    if (payload === null) {
      payload = {}
    }
    state.setting = payload
  },
  [types.SET_ROOMS] (state, payload) {
    state.rooms = payload.data
    state.pagination = payload.meta.pagination
  },
  [types.SET_CHAT] (state, payload) {
    state.chat = payload
  }
}
