export default [
  {
    name: 'event',
    path: '/event/setting',
    component: () => import(/* webpackChunkName: "event" */ './Setting'),
    meta: {
      showNavbar: true,
      title: 'Configurações Gerais',
      icon: 'dvr',
      label: 'Configurações'
    }
  },
  {
    name: 'integration',
    path: '/event/integration',
    component: () => import(/* webpackChunkName: "event" */ './Integration'),
    meta: {
      showNavbar: true,
      title: 'Integrações',
      icon: 'dvr',
      label: 'Integrações'
    }
  },
  {
    name: 'certificate',
    path: '/event/certificate',
    component: () => import(/* webpackChunkName: "event" */ './Certificate'),
    meta: {
      showNavbar: true,
      title: 'Meu Evento',
      icon: 'dvr',
      label: 'Certificação'
    }
  },
  {
    name: 'customization',
    path: '/event/customization',
    component: () => import(/* webpackChunkName: "event" */ './Custom'),
    meta: {
      showNavbar: true,
      title: 'Meu Evento',
      icon: 'dvr',
      label: 'Customização'
    }
  },
  {
    name: 'terms-conditions',
    path: '/event/terms-conditions',
    component: () => import(/* webpackChunkName: "event" */ './Terms'),
    meta: {
      showNavbar: true,
      title: 'Meu Evento',
      icon: 'dvr',
      label: 'Termos e Condições'
    }
  }
]
