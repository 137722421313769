import http from '@/http'
const URL = '/admin/rooms'

export const list = async (params) => {
  const list = (await http.get(URL + `/${params.room_id}/quiz?include=answers`, { params: params }))
  return list
}

export const find = async (params) => {
  let one = null
  if (params.id) {
    one = (await http.get(URL + `/${params.room_id}/quiz/${params.id}?include=answers`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.room_id}/quiz/${payload.id}`, payload)
  }
  return http.post(URL + `/${payload.room_id}/quiz`, payload)
}

export const deleteQuiz = async (payload) => http.delete(URL + `/${payload.room_id}/quiz/${payload.id}`)
export const deleteAnswer = async (payload) => http.delete(URL + `/${payload.room_id}/quiz/${payload.id}/answer`)
export const changeStatus = (payload) => http.put(URL + `/${payload.room_id}/quiz/${payload.id}/status`, payload)
export const orderList = async (payload) => {
  if (payload.room_id) {
    return http.put(URL + `/${payload.room_id}/quiz/sequence`, payload)
  }
}

export default {
  list,
  find,
  save,
  changeStatus,
  deleteQuiz,
  deleteAnswer,
  orderList
}
