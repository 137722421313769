import { routes as auth } from '../modules/auth'
import { routes as home } from '../pages/home'
import dashboards from '@/modules/dashboards/routes'
import { routes as users } from '../pages/users'
import { routes as roles } from '../pages/roles'
import { routes as event } from '../pages/event'
import { routes as settingFeed } from '../pages/event/feed'
import { routes as emailTemplate } from '../pages/event/emailTemplate'
import { routes as menu } from '../pages/event/menu'
import { routes as form } from '../pages/event/form'
import { routes as speaker } from '../pages/speakers'
import { routes as schedule } from '../pages/schedules'
import { routes as room } from '../pages/rooms'
import { routes as partner } from '../pages/partners'
import { routes as product } from '../pages/store/products'
import { routes as order } from '../pages/store/orders'
import { routes as guide } from '../pages/guide'
import { routes as faq } from '../pages/faq'
import { routes as participant } from '../pages/participants'
import { routes as feed } from '../pages/feed'
import { routes as report } from '../pages/reports'
import { routes as survey } from '../pages/survey'
import { routes as gamification } from '../pages/gamification'
import { routes as archive } from '../pages/archives'
import { routes as video } from '../pages/videos'
import { routes as raffle } from '../pages/raffle'

export default [
  ...auth,
  ...home,
  ...dashboards,
  ...users,
  ...roles,
  ...event,
  ...settingFeed,
  ...emailTemplate,
  ...menu,
  ...form,
  ...speaker,
  ...schedule,
  ...room,
  ...participant,
  ...feed,
  ...report,
  ...survey,
  ...gamification,
  ...archive,
  ...raffle,
  ...partner,
  ...product,
  ...order,
  ...guide,
  ...faq,
  ...video
]
