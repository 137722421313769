import services from '../services'
import * as types from './mutation-types'

export const getList = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_POLLS, res.data)
  })
)

export const show = async ({ commit }, params) => {
  await services.find(params).then(res => {
    commit(types.SET_POLL, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_POLL, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  if (obj.room_id) {
    await services.orderList(obj).then(res => {
      commit(types.SET_POLLS, res.data)
      return res.data
    })
  }
}

export const deletePoll = async ({ commit }, obj) => {
  await services.deletePoll(obj).then(res => {
    return res
  })
}

export const deleteOption = async ({ commit }, obj) => {
  await services.deleteOption(obj).then(res => {
    return res
  })
}
