import { convertIntToBoolean } from '@/helpers'

export default {
  'RAFFLE' (state) {
    return state.raffle
  },
  'RAFFLES' (state) {
    const list = convertIntToBoolean(state.raffles, ['status'])
    return list
  }
}
