export default [
  {
    name: 'store-products',
    path: '/store/products',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Produtos',
      icon: 'dvr',
      label: 'Produtos'
    }
  }
]
