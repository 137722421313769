import services from '../services'
import * as types from './mutation-types'

export const getSymplaEvents = async ({ commit }, params) => {
  const data = await services.listEvents(params).then(res => {
    return res.data
  })
  return data
}

export const getEvent = async ({ commit }, obj) => {
  await services.show(obj).then(res => {
    commit(types.SET_SYMPLAEVENT, res.data.data)
    return res.data.data
  })
}
export const getAllEvents = async ({ commit }, obj) => {
  await services.getAllEvents(obj).then(res => {
    commit(types.SET_SYMPLAEVENTS, res.data)
  })
}
export const getAll = ({ commit }, params) => (
  services.getTickets(params).then(res => {
    commit(types.SET_SYMPLATICKETS, res.data)
  })
)

export const getTicket = async ({ commit }, id) => {
  await services.showTicket(id).then(res => {
    commit(types.SET_SYMPLATICKET, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveTicket(obj).then(res => {
    commit(types.SET_SYMPLATICKET, res.data.data)
    return res
  })
}

export const deleteEvent = async ({ commit }, obj) => {
  const data = await services.deleteEvent(obj).then(res => {
    return res
  })
  return data
}

export const deleteTicket = async ({ commit }, obj) => {
  const data = await services.deleteTicket(obj).then(res => {
    return res
  })
  return data
}
