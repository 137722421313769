import { convertIntToBoolean } from '@/helpers'

export default {
  'SURVEY' (state) {
    return state.survey
  },
  'SURVEYS' (state) {
    if (state.surveys.length > 0) {
      let surveys = convertIntToBoolean(state.surveys, ['status'])
      return surveys
    }
    return []
  }
}
