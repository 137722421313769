import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_GAMIFICATIONS, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_GAMIFICATION, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.store(obj).then(res => {
    commit(types.SET_GAMIFICATION, res.data.data)
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}

export const changeStatusGamification = async ({ commit }, obj) => {
  await services.changeStatusGamification(obj).then(res => {
    return res
  })
}

export const changeRankingGamification = async ({ commit }, obj) => {
  await services.changeRankingGamification(obj).then(res => {
    return res
  })
}

export const deleteSetting = async ({ commit }, obj) => {
  await services.remove(obj).then(res => {
    return res
  })
}

export const resetRanking = async ({ commit }) => {
  await services.resetRanking().then(res => {
    return res
  })
}
