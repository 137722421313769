export default {
  namespaced: true,
  state () {
    return {
      filterStartDate: null,
      filterEndDates: null
    }
  },
  mutations: {
    filterDates (state, payload) {
      state.filterStartDate = payload[0]
      state.filterEndDates = payload[1]
    }
  }
}
