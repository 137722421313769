export default [
  {
    name: 'setting-feed',
    path: '/event/feed',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Configuração do Feed',
      icon: 'dvr',
      label: 'Feed'
    }
  }
]
