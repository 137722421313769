import * as types from './mutation-types'
import { convertIntToBoolean } from '@/helpers'

let defaultObject = () => ({
  data: {}
})

export default {
  [types.SET_STREAMING] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    convertIntToBoolean(payload, ['status', 'streaming_main'])
    state.streaming = payload
  },
  [types.SET_STREAMINGS] (state, payload) {
    state.streamings = []
    if (payload.data.length > 0) {
      for (let streaming of payload.data) {
        convertIntToBoolean(streaming, ['status', 'streaming_main'])
      }
      state.streamings = payload.data
    }
    state.pagination = payload.meta.pagination
  }
}
